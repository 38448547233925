import React from 'react';

function Footer({footerShow}) {
    
    return (
        <footer className="footer"style={{padding: "20px calc(24px / 2)",
                position: "absolute",  right: "0", color: "#74788d",marginTop:"20px",
                height: "60px",
                WebkitBoxShadow: "0 2px 4px rgba(0,0,0,.08)",
                BoxShadow: "0 2px 4px rgba(0,0,0,.08)",
                background:"#ffffff",left:footerShow ? "240px":"70px"}} >
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              2021 
            </div>
            <div className="col-sm-6">
              <div className="text-sm-right d-none d-sm-block">
                <i className="mdi mdi-heart text-danger" /> zillskill
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default Footer;
