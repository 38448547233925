import React from 'react';
import {Button} from 'react-bootstrap';
import callApi from '../../../util/apiCaller';


class ResetPassword extends React.Component{
    constructor(){
        super();
        this.state = {
            password:'',
            confirmPassword:'',
            errormsg:null,
            validatepassword: true,
            isTokenExpired: false,
            error: "",
        };
    }

    // handleChangePassword = (e)=> {
    //     this.setState({password: e.target.value});
    //   }
    //   handleChangeConfirmpassword = (e) => {
    //     this.setState({confirmpassword: e.target.value});
    //   }
     validate = (field,value) => {
         let errormsg = '';
         let validatepassword = true;
        if(field === "password" || field === "confirmPassword"){
          if(value.trim().length===0){
              errormsg = "This field is required";
              validatepassword = false;
          }
        }
        if(field === "confirmPassword"){
          if(value !== this.state.password){
            errormsg = "Passwords don't match";
            validatepassword = false;
          }else{
            errormsg = "";
            validatepassword = true;
          }
        }
       this.setState({errormsg: errormsg, validatepassword: validatepassword});
        
      }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.validate(name, value);

    }
    handleSubmit = (e) => {
        e.preventDefault();
        const token = this.props.match.params.token || null
        let body = {password: this.state.password,
                    confirmPassword: this.state.confirmPassword}
        callApi(`auth/changePassword/`+ token, 'post', body).then(res => {
            console.log(res);
            console.log(res.data.message);
            if(res){
                
                alert(res.data.message)
                this.props.history.push({
                    pathname: '/login',
                    state:  res.data.message
                 } )

            }
            // if (res && res.user) {
            //     if(res.user.ok){
            //         window.localStorage.setItem('token', res.user.token);
            //         window.location = "/";
            //     }
            // } else {
            //     this.showMessage(res.message, 'error');
            // }

        })
        .catch(error =>{
            this.setState({ error: "Your token is expire! Please do the forget password again!" })
            console.log(error);
        });
        
    }
    componentDidMount(){
        const token  = this.props.match.params.token || null;
        console.log(token);
        callApi(`auth/reset/`+ token, 'get' ).then(res => {
            console.log(res);
            console.log(res.message);
            if(res.user.ok){
                this.setState({messageFromServer: res.message})
            }
            else{
                this.setState({isTokenExpired: true})
                this.setState({error: res.message})
            }
            // if (res && res.user) {
            //     if(res.user.ok){
            //         window.localStorage.setItem('token', res.user.token);
            //         window.location = "/";
            //     }
            // } else {
            //     this.showMessage(res.message, 'error');
            // }

        })
        .catch(error =>{
            alert("Oppss..! please refresh and try again.");
            console.log(error);
        });

    }


    render(){
        const {password, confirmPassword, submitted, error, loading} = this.state
        const isTokenExpired = this.state.isTokenExpired;
        
        if (isTokenExpired) {
          return (
              <div> <h1>Token expired ....!Refresh the page</h1></div>
          )
        }
    
        return(
            <div className="col-md-6 col-md-offset-3">
            <h2>Reset Password Screen</h2>
            
            <form name="form" onSubmit={this.handleSubmit}>
                <div className={'form-group' + (submitted && !password ? ' has-error' : '') }>
                    <label htmlFor="username">password</label>
                    <input type="password" required className="form-control" name="password" value={password} 
                    placeholder="Password" onChange={this.handleChange} />
                    {submitted && !password &&
                        <div className="help-block">password is required</div>
                    }
                    <span>{this.state.errormsg}</span>
                </div>
                <div className={'form-group' + (submitted && !confirmPassword ? ' has-error' : '') }>
                    <label htmlFor="username">Confirm Password</label>
                    <input type="password" required className="form-control" name="confirmPassword" value={confirmPassword} 
                    placeholder="Confirm Password" onChange={this.handleChange} />
                    {submitted && !confirmPassword &&
                        <div className="help-block">password is required</div>
                    }
                </div>
                <div className="form-group">
                    <button className="btn btn-primary" type="submit" disabled={loading}>Submit</button>
                    <Button style={{margin:"10px"}}
                    onClick= {() =>  this.props.history.push("/signup")}
                    id="account-btn">Register
                    </Button>
                    {loading &&
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                </div>
                {error &&
                    <div className={'alert alert-danger'}>{error}</div>
                }
            </form>
        </div>
        );
    
    }
}


export default ResetPassword;