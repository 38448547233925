import React, { Component } from 'react'
import CloseIcon from "@material-ui/icons/Close"
import "./CloseButton.css"

export class CloseButton extends Component {
    render() {
        return (
            <div className="close_button">
                <CloseIcon />
            </div>
        )
    }
}

export default CloseButton
