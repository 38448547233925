import React, { Component } from 'react';
import CacheBuster from './CacheBuster';
import App from './App';

class MegaApp extends Component {
    render() {
        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {

                    if (false) return <div>false</div>;

                    if (!true && !isLatestVersion) {
                        refreshCacheAndReload();
                    }
                    return (
                        <App />
                    );
                }}
            </CacheBuster>
        );
    }
}

export default MegaApp;
