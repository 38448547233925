import React from 'react';

class SearchPage extends React.Component {
   
  
    render() {
       
        return (
          <div>
     
     <form className="app-search d-none d-lg-block">
          <div className="position-relative"style={{top:"10px"}}>
            <input type="text" className="form-control cu_in" placeholder="Search..." style={{backgroundColor:"#f1f5f7",position: "relative",
    bottom: "9px",}}/>
           <span><i className="ri-search-line" aria-hidden="true"></i></span>
          </div>
        </form>
        
        
    
      
            </div>
        );
    }
}

export default SearchPage ; 