import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import InputTag from '../../../components/InputTag/InputTag'

export class ProfilePage extends Component {
    render() {
        return (
            <>
                { this.props.isAuthenticated ?
                <div style={{marginLeft:this.props.showSidebar? "240px":"70px", minHeight: "100vh", marginTop: "90px"}}>
                    <h1>Hello</h1>
                </div>
                : <Redirect to="/login" />
                }
            </>
        )
    }
}

export default ProfilePage
