import React from 'react';
// import {Button, Form, FormControl} from 'react-bootstrap';
import callApi from '../../../util/apiCaller';


class ForgotPassword extends React.Component{
    constructor(){
        super();

        this.state = {
            email:'',
            showError: false,
            messageFromServer:'',
        }
    }
    handleChangeEmail = (e) => {
        this.setState({email: e.target.value});
      }

      handleSubmit = e =>{
        e.preventDefault();
        let body = {email: this.state.email}
        callApi(`auth/forgot`, 'post', body).then(res => {
            console.log(res);
            if(res && res.user){
                if(res.user.ok){
                    const reset = res.model.resetLink.split("account")[1]
                    this.setState({messageFromServer: res.message})
                    this.props.history.push(`${reset}`);
                }else{
                    alert(res.message)
                }
                
            }else{
                alert("Oppss..! please refresh and try again.")
            }

        })
        .catch(error =>{
            // alert("Oppss..! please refresh and try again.");
            alert("Oppss..! please refresh and try again.");
        });
      }

    render(){
        const {email, submitted, error, loading} = this.state

       
        return(
            <div className="col-md-6 col-md-offset-3">
            <h2>Forgot Password Screen</h2>
            <form name="form" onSubmit={this.handleSubmit}>
                <div className={'form-group' + (submitted && !email ? ' has-error' : '') }>
                    <label htmlFor="username">Email</label>
                    <input type="email" required className="form-control" name="email" value={email} placeholder="Enter registered email" onChange={this.handleChangeEmail} />
                    {submitted && !email &&
                        <div className="help-block">email is required</div>
                    }
                </div>
                <div className="form-group">
                    <button className="btn btn-primary"
                    type = "submit"
                    disabled={loading}>Submit</button>
                    {/* <Button style={{margin:"10px"}}
                     onClick= {() =>  this.props.history.push("/signup")}
                     id="account-btn">Register
                    </Button> */}

                <div> <span style={{color:"green"}}>{this.state.messageFromServer}</span></div>
                    {loading &&
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loading" />
                    }
                </div>
                {error &&
                    <div className={'alert alert-danger'}>{error}</div>
                }
            </form>
        </div>
        );
    }
}



export default ForgotPassword;