import React, {Component} from 'react';
import {
    Button,
} from 'react-bootstrap';
import SearchField from "react-search-field";
import ListTableRow from './ListTableRow';
import ListTableHeader from './ListTableHeader';
import Pagination from "react-js-pagination";


const Add = "Add User"


class ListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false,
        };
    }

    componentDidMount() {
        this.setState({
            size: this.props.size,
            page: this.props.page,
            isLoading: this.props.isLoading,
            count: this.props.count,
        });
    }

    togglePanel = () => {
        this.setState({
           isCollapsed: !this.state.isCollapsed
        });
    };

    refreshTable = () => {
        let filters = {
            pageNum: 1,
            pageSize: this.props.size,
            qtext: ''
        };
        this.props.filters(filters);
    };

    changeSize = (e) => {
        let filters = {};
        filters.pageNum = 1;
        filters.pageSize = e.target.value;
        filters.qtext = this.props.qtext
        this.setState({
            size: e.target.value
        });
        this.props.filters(filters);
    };

    changePage = (page) => {
        let filters = {};
        filters.pageNum = page;
        filters.pageSize = this.props.size;
        filters.qtext = this.props.qtext
        this.setState({
            page: page
        });
        this.props.filters(filters);
    };

    getPages = (count, pagesize) => {
        if (count > 0) {
            return Math.ceil(count / pagesize);
        } else {
            return 0;
        }
    };

    getPageStart = (pageNum = 1, pageSize = 50, count = 0) => {
        if (parseInt(count) > 0) {
            return parseInt((pageNum - 1) * pageSize + 1);
        } else {
            return 0;
        }
    };

    getPageEnd = (pageNum = 1, pageSize = 50, count = 0) => {
        if (parseInt(count) > 0) {
            if ((count - ((pageNum - 1) * pageSize)) < pageSize) {
                return count;
            } else {
                return pageNum * pageSize;
            }
        } else {
            return 0;
        }
    };

    prevPage(){
        if(this.state.page > 1){
            this.setState((prevState) => {
                return {
                    ...prevState,
                    page: prevState.page - 1
                }
            }, () => this.changePage(this.state.page))
        }
    }

    nextPage(){
        if(this.state.page < Math.ceil(this.state.count/this.state.size)){
            this.setState((prevState) => {
                return {
                    ...prevState,
                    page: prevState.page + 1
                }
            }, () => {this.changePage(this.state.page)
            console.log(this.state.page)})
        }
    }

    render() {
        return (
            <div className="col-xs-12 col-lg-12">
                <div className="panel panel-default">
                    <div className="panel-heading"><h5>{this.props.name} - {this.props.count} </h5>
                        <div className="panel-ctrls">
                            <div className="dataTables_filter pull-right"><label className="panel-ctrls-center">
                            </label></div>
                            <div style={{marginRight:"10px"}}>
                            <i className="separator pull-right"></i>
                            <div className="dataTables_filter pull-right"><label className="panel-ctrls-center">
                                <select className="dust-pager-size form-control ml-2" defaultValue={this.state.size} onChange={(e) => this.changeSize(e)}
                                >
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                </select>
                                    {/* priority */}
                                </label></div>
                            <i className="separator pull-right"></i>
                            <div className="dataTables_filter pull-right">
                                <label className="panel-ctrls-center"><Button onClick={() => this.refreshTable()} className="btn btn-md btn-default ml-2"
                                data-anchor="mgrEnquiryContainer"><i className="fa fa-refresh fa-fw"></i></Button></label>
                            </div>
                            <i className="separator pull-right"></i>
                            <div className="dataTables_filter pull-right">
                                <label className="panel-ctrls-center">
                                    <Button
                                        onClick={() => this.togglePanel()}
                                        className="btn btn-md btn-default ml-2"
                                    ><i className={this.state.isCollapsed ? "fa fa-arrow-circle-down fa-fw" : "fa fa-arrow-circle-up fa-fw"}></i></Button>
                                </label>
                            </div>
                            <div className="dataTables_filter pull-right">
                                <label className="panel-ctrls-center">
                                    <Button onClick={() => this.props.displayModal()} className="btn btn-md btn-default ml-2">
                                        {Add}
                                    </Button>
                                </label>
                            </div>
                            <i className="separator pull-right"></i>
                            </div>

                           <div className="col-md-6" style={{marginTop:"31px",paddingLeft:"0px"}}>
                           
                            <div>
                            <SearchField
                                placeholder="Search..."
                                onChange={(e) => this.props.handleQueryChange(e)}
                                searchText={this.props.qtext}
                                classNames="test-class" style={{color:"#000000"}}
                                />
                            </div>
                            
                           </div>
                        </div>
                    </div>
                    <div className={this.state.isCollapsed ? "panel-body no-padding hide" : "panel-body no-padding"}
                         style={{minHeight: "400px", overflowY: "auto", fontSize: "12px"}}>
                        <table className="table table-striped table-fixed-header table-bordered dataTable no-footer"
                               style={{minWidth: "100%", textAlign: "center",minHeight:"25px"}}>
                            <thead className="text-center">
                            <ListTableHeader  style={{}}/>
                            </thead>
                            <tbody id="activeEventList" style={{Height: "150px", overflow: "auto",fontSize:"14px"}}>
                            { !this.state.isCollapsed ? 
                            <>
                            {this.props.list.map(item => (
                                <ListTableRow
                                    user={item}
                                    key={item._id}
                                    id={item._id}
                                    pageSize={this.state.pageSize}
                                    showMessage={this.props.showMessage}
                                    selectUser={this.props.selectUser}
                                    deleteUser={this.props.deleteUser}
                                    updateUser={this.props.updateUser}
                                    displayModal={this.props.displayModal}
                                    displayUpdateModal={this.props.displayUpdateModal}
                                    handleShowShell={this.props.handleShowShell}
                                />
                            ))} 
                            </> : null }
                            </tbody>

                        </table>
                    </div>

                    <div className={this.state.isCollapsed ? "panel-footer hide" : "panel-footer"}>
                        <div className="row mt-4">
                            <div className="col-sm-6 col-lg-5">
                                <div className="dataTables_info" id="example_info" role="status" aria-live="polite">
                                    <div className="" id="example_info" role="status">
                                        Showing {this.getPageStart(this.state.page, this.state.size, this.props.count)}  &nbsp;
                                        to {this.getPageEnd(this.state.page, this.state.size, this.props.count)}
                                        &nbsp;of {this.props.count} entries
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-6">
                                <div className="dataTables_paginate paging_bootstrap" id="example_paginate">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.props.page}
                                    itemsCountPerPage={this.props.size}
                                    totalItemsCount={this.props.count}
                                    pageRangeDisplayed={5}
                                    onChange={this.changePage.bind(this)}
                                    /> 
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListTable;
