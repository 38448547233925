import CloseIcon from '@material-ui/icons/Close'
import React, { Component } from 'react'
import "./InputTag.css"


export class InputTag extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="inputTag">
                <div className="inputTag_items">
                    {this.props.datas.map((data, index) => (
                        <section key={index} className="inputTag_item">
                            <span>{data}</span>
                            <span onClick={() => this.props.handleDeleteItem(`${index}`)}><CloseIcon fontSize="small" /></span>
                        </section>
                    ))}
                </div>
                <textarea value={this.props.value} onChange={(e) => this.props.handleSetValue(e.target.value)} onKeyDown={(e) => this.props.handleKeyDown(e)}></textarea>
            </div>
        )
    }
}

export default InputTag
